.portfolio {
    padding-top: 8px;
    min-height: 100vh;
}

.portfolio > p{
    /* font-size: 1.25rem; */
    margin: 18px 0px 48px 0px;
}

.portfolio h2 {
    margin-bottom: 12px
}

@media only screen and (max-width: 767px) {
 .portfolio{
    padding-top: 0px;
 }
}
