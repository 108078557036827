.about {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
}

.text-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.text-box p {
    max-width: 55%;
}

.left-col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (max-width: 767px) {
    .text-box p {
        max-width: 100%;
    }
    .text-box {
        gap: 24px;
    }

    .left-col a{
        display: none;
    }
}


