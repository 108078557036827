.hero h1, p{
    margin:0;
    padding: 0;
}

.hero-content {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.hero-content p{
    max-width: 65%;
}
.hero-content .btn-outline-white{
    display: block;
}



@media only screen and (max-width: 767px) {
    .hero-content p{
        max-width: 100%;
    }

    .hero-content {
        height: calc(100vh - 240.5px);
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .hero-line:last-child{
        flex-grow: 1;
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

}
