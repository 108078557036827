.navbar{
    padding: 32px 0px 32px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.navbar-nav{
    display: flex;
    flex-direction: row;
    gap: 36px;
    flex-wrap: nowrap;
}

.nav-link {
    color:#000;
    align-self: center;
}

.navbar-brand {
    font-size: 1.25rem;
    font-weight: 600;
    align-self: center;
}

@media only screen and (max-width: 767px) {
    .navbar-nav{
        display: flex;
        flex-direction: row;
        gap: 12px;
        flex-wrap: nowrap;
    }

}