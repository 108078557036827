.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contacts {
  margin-top:100px;
}

.contacts .flex-row {
  justify-content: flex-start;
  gap: 24px;
}
.contacts .flex-row p {
  font-size: 1.25rem;
}

.copyright {
  margin: 48px 0px 48px;
}

.copyright p, .copyright a {
  font-size: 0.8rem;
  color: #333;
}

.contacts h3 {
  margin: 48px 0px 24px;
}

.react-icon {
  -webkit-animation: rotate 5s normal linear infinite;
  animation: rotate 8s normal linear infinite;
}



@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@media only screen and (max-width: 767px) {
  .contacts .flex-row {
    justify-content: flex-start;
    gap: 12px;
  }

  .copyright {
    align-items: flex-end;
    margin: 24px 0px 24px;
  }
}
