.project {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 56px 0px 56px 0px;
  border-style: solid;
  border-width: 0px 0px 0px 1px;
  padding: 0px 0px 0px 24px;
}

.project > span {
  margin-top: 48px;
}

.project-details {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.project-details p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 150%;
  letter-spacing: 0px;
}

.project-details .title {
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0px;
  line-height: 90%;
  margin: 0;
}

.project-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.info {
  display: flex;
  flex-direction: row;
  gap: 6px;
  font-size: 0.9rem;
}

.info span:first-child {
  font-weight: 600;
}



.partner-logo {
  filter: grayscale(1);
  opacity: 0.7;
  margin-left: 24px;
  height: 24px;
}

.info img:first-of-type{
  margin-left: 8px;
}

@media only screen and (max-width: 767px) {
  .project-info {
    gap: 12px;
  }

  .partner-logo {
    margin-left: 18px;
    height: 20px;
  }

  .project-details {
    width: 90%;
  }
}
