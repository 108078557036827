@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;200;300;400;500;600;700;800;900&display=swap");

*, html {
  scroll-behavior: smooth !important;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Inter Tight", sans-serif;
  color: #333;
  background-color: #fafafa;
  scroll-behavior: smooth !important;
}

.hand-box{
  height: 100vh;
  width: 100vw;
  display: flex;
}

.hand-box p {
  font-size: 10rem;
  text-align: center;
  width: 100%;
  align-self: center;
}


svg{
  vertical-align: middle;
}

.box-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 92%;
}

.section {
  min-height: 100vh;
  width: 100%;
  /* scroll-snap-align: center; */
}


h1 {
  font-size: 8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -4px;
  line-height: 90%;
}

h2,h3 {
  font-size: 5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -3px;
  line-height: 90%;
}

p {
  font-size: 2.4rem;
  letter-spacing: -0.2px;
  line-height: 115%;
  font-weight: 400;
}

.btn-outline-white {
  background-color: transparent;
  border-color: #333;
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
  padding: 16px 32px 16px 32px;
  min-width: 80px;
  text-decoration: none !important;
  color: #333;
  width: fit-content;
  text-align: center;
  transition: 0.3s ease-in-out;
}

.btn-outline-white:hover {
  background-color: #333;
  color: #fafafa;
}

/* Stile di default per il link */
a {
  text-decoration: none; /* Rimuove la sottolineatura di default */
  position: relative; /* Per posizionare l'animazione rispetto al link */
  color: #333; /* Colore del testo del link */
  width: fit-content;
}

/* Stile della linea per l'animazione */
a:not(.btn-outline-white)::before {
  content: ''; /* Contenuto generato prima del link */
  position: absolute; /* Posizionamento assoluto rispetto al link */
  bottom: -8px; /* Posizionamento alla base del link */
  right: 100%; /* Inizialmente nascosto a sinistra */
  width: 0; /* Larghezza iniziale della linea (0) */
  height: 1px; /* Spessore della linea */
  background-color: #333; /* Colore della linea */
  transition: width 0.2s ease-out, right 0.2s ease-out; /* Transizione */
}

/* Animazione quando si passa il mouse sopra il link */
a:not(.btn-outline-white):hover::before {
  width: 100%; /* Larghezza completa al passaggio del mouse */
  right: 0; /* Linea che appare da sinistra verso destra */
}

a:hover {
  color:#4d4d4d;
}

/* Animazione di uscita quando il mouse esce dal link */
a:not(.btn-outline-white)::before {
  width: 0; /* Rientra quando il mouse esce */
  transition-delay: 0.2s; /* Ritardo per far partire l'animazione di uscita */
}

.line {
  overflow: hidden;
  min-width: min-content;
}





@media only screen and (max-width: 1180px) {
  h1 {
    font-size: 6rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -3px;
    line-height: 100%;
  }
  h2,h3 {
    font-size: 5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -1.4px;
    line-height: 90%;
  }

  p {
    font-size: 2rem;
    letter-spacing: -0.2px;
    line-height: 115%;
    font-weight: 400;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -2.5px;
    line-height: 90%;
  }
  h2,h3 {
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -1.4px;
    line-height: 90%;
  }

  p {
    font-size: 1.8rem;
    letter-spacing: -0.2px;
    line-height: 115%;
    font-weight: 400;
  }
}
